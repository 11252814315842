import posthog from '../../../node/node_modules/posthog-js/dist/module';

let initialized = false;


export const enableUxTracking = (): void => {
    const params = new URLSearchParams(window.location.search);
    posthog.init('phc_p1KTCQKlUpbqTssTwk2oz700DWxPdlcJkMPINxtgnb3',
        {api_host: 'https://app.posthog.com'});
    posthog.register({env: 'prod'});

};