/**
 * Start fadeIn transition, when an fadeIn Element comes into view
 */
export const bindFadeInObserver = (): void => {
    const fadeInElements = document.querySelectorAll('.--fadeInTop');
    const fadeInObserver = new IntersectionObserver(((entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                entry.target.classList.add('is-inview');
            }
        });
    }));
    fadeInElements.forEach(element => fadeInObserver.observe(element));
};
