import axios from '../../../../node/node_modules/axios';

export const searchApiCall = (): void => {
    const searchfield = document.querySelector('form');
    searchfield?.addEventListener('submit', function(event) {
        event.preventDefault();
        const query = new FormData(searchfield);
        const queryvalue = query.get('queryvalue');
        axios.get('https://api.project-count.com/Search', { params: {query: queryvalue} ,

            
            },)
            .then(apiResponse => {
                const output = apiResponse.data
                console.log(output)
            })
            .catch (error => {
                console.log(error) 
            })        
    });
};
