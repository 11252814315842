export const bindFeaturesControlMenu = (): void => {
    bindClickOnControlElement();
    bindFeaturesObserver();
};

/**
 * Scroll to the Feature, when clicking on control item
 */
const bindClickOnControlElement = () => {
    const controlMenuItems = document.querySelectorAll('.scfeaturesmain__controls .item');
    // Add event listener to each item of the controlMenu
    controlMenuItems.forEach(controlElement => {
        controlElement.addEventListener('click', () => {
            const targetSectionNumber = controlElement.getAttribute('feature-scroll-to');
            const targetElement = document.querySelector('#scfeaturesmain__row-' + targetSectionNumber) as HTMLElement;

            targetElement.scrollIntoView({
                behavior: 'smooth',
            });
        });
    });
};

/**
 * Move the purple line of features page control menu according to visible section
 */
const bindFeaturesObserver = (): void => {
    const featureElements = document.querySelectorAll('.scfeaturesmain__content-row');

    const visibleFeatureIds: number[] = [];

    const featuresObserver = new IntersectionObserver(((entries) => {
        entries.forEach(entry => {
            const controlMenuItemNumber = parseInt(entry.target.getAttribute('data-scroll-call'));

            if (!entry.isIntersecting) {
                // Remove element from visibleFeatureIds array
                const indexOfVisible = visibleFeatureIds.indexOf(controlMenuItemNumber);
                if (indexOfVisible === -1) {
                    return;
                }
                visibleFeatureIds.splice(indexOfVisible, 1);

                // E.g. when scrolled to top, there can be no features visible at all
                if (visibleFeatureIds.length === 0) {
                    return;
                }
            } else {
                // Feature became visible => add it to the visibleFeatureIds
                visibleFeatureIds.push(controlMenuItemNumber);
            }

            // Always mark the Feature which is nearest to the top
            visibleFeatureIds.sort();

            const controlMenuInner = document.querySelector('.scfeaturesmain__controls-list')
                .children[0] as HTMLElement;
            const controlMenuItem = controlMenuInner.children[visibleFeatureIds[0] - 1] as HTMLElement;

            movePurpleLine(visibleFeatureIds, controlMenuItem, controlMenuInner);
            moveActiveClass(visibleFeatureIds, controlMenuItem);
        });
    // When 90% of element is visible, count it as visible
    }), {threshold: 0.9});
    featureElements.forEach(element => featuresObserver.observe(element));
};

const movePurpleLine = (visibleFeatureIds: number[], controlMenuItem: HTMLElement, controlMenuInner: HTMLElement) => {
    const lineActive = document.querySelector('.scfeaturesmain__controls .line') as HTMLElement;
    // Move the purple active line to the selected element
    const positionActiveLine = controlMenuItem.offsetTop - controlMenuInner.offsetTop;
    lineActive.style.transform = `translateY(${positionActiveLine}px)`;
};

/**
 * Move the 'active' class to the control element (it is highlighted)
 */
const moveActiveClass = (visibleFeatureIds: number[], controlMenuItem: HTMLElement) => {
    document.querySelector('.scfeaturesmain__controls .item.active').classList.remove('active');
    controlMenuItem.classList.add('active');
};
