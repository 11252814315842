const TRANSITION_TIMER = 300;

export const bindClipboardCopy = (): void => {
    const clipboard = document.querySelector('.clipboard');
    const copyTooltip = document.querySelector('.copy-link');
    let copied = false;

    clipboard?.addEventListener('click', (event) => {
        const temp = document.createElement('input');
        const url = window.location.href;
        document.body.appendChild(temp);
        temp.value = url;
        temp.select();
        document.execCommand('copy');
        document.body.removeChild(temp);

        copyTooltip.textContent = 'Copied!';
        copied = true;
    });

    clipboard?.addEventListener('mouseleave', () => {
        if (!copied) {
            return;
        }
        setTimeout(() => {
            copyTooltip.textContent = 'Copy the link';
            copied = false;
        }, TRANSITION_TIMER);
    });
};
