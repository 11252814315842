export const closeActiveSibling = (element: HTMLElement, selector: string): void => {
    let sibling = element.parentNode.firstChild as HTMLElement;
    while (sibling) {
        if (sibling.nodeType === 1 && sibling !== element && sibling.classList.contains(selector)) {
            sibling.classList.remove(selector);
            break;
        }
        sibling = sibling.nextSibling as HTMLElement;
    }
};
