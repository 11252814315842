import {bindClipboardCopy} from './components/bindClipboardCopy';
import {bindFadeInObserver} from './components/bindFadeInObserver';
import {bindCookiePreferences} from './components/bindCookiePreferences';
import {bindFaq} from './components/bindFaq';
import {bindFeaturesControlMenu} from './components/bindFeaturesControlMenu';
import {bindHeaderToggleMobile} from './components/bindHeaderToggleMobile';
import {bindMenuItemClick} from './components/bindMenuItemClick';
import {bindMobileFooterAccordion} from './components/bindMobileFooterAccordion';
import {bindMobileNav} from './components/bindMobileNav';
import {bindNotFoundAnimation} from './components/bindNotFoundAnimation';
import {bindPricingAnimation} from './components/bindPricingAnimation';
import {bindScrollDownButton} from './components/bindScrollDownButton';
import {bindWindowResize} from './components/bindWindowResize';
import {searchApiCall} from './components/searchApiCall';
import {bindReadMore} from './components/bindReadMore'
import {addToCart} from './components/bindAddToCart'
import {getCartItems} from './components/bindGetCartItems'

document.addEventListener('DOMContentLoaded', () => {
    bindCookiePreferences();
    bindHeaderToggleMobile();
    bindMenuItemClick();
    bindMobileNav();
    bindWindowResize();
    bindClipboardCopy();
    bindNotFoundAnimation();
    bindFaq(); // Used in FAQ and Pricing.
    if (location.pathname === '/pricing') {
        bindPricingAnimation();
    }
    bindMobileFooterAccordion();
    bindScrollDownButton(); // Used in Features and Company pages.
    bindFeaturesControlMenu();
    bindFadeInObserver();
    searchApiCall();
    if (location.pathname === '/journey') {
        bindReadMore();
    }
    if (location.pathname === '/books') {
        addToCart();
        getCartItems();
    }
    

}, false);
