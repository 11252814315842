import {getElementWidth} from './getElementSize';
import {screen} from './Screen';

const setStorageDevice = (): void => {
    const windowSize = getElementWidth(document.body);
    if (windowSize <= screen.mobile) {
        localStorage.setItem('device', 'mobile');
    } else if (windowSize <= screen.tablet) {
        localStorage.setItem('device', 'tablet');
    } else {
        localStorage.setItem('device', 'desktop');
    }
};

const reloadOnResize = (): void => {
    const windowSize = getElementWidth(document.body);
    const device = localStorage.getItem('device');
    if (windowSize <= screen.mobile && device !== 'mobile') {
        location.reload();
        setStorageDevice();
    } else if (windowSize <= screen.tablet && windowSize > screen.mobile && device !== 'tablet') {
        location.reload();
        setStorageDevice();
    } else if (windowSize > screen.tablet && device !== 'desktop') {
        location.reload();
        setStorageDevice();
    }
};

export const bindWindowResize = (): void => {
    setStorageDevice();
    window.addEventListener('resize', () => {
        setTimeout(function () {
            reloadOnResize();
        }, 100);
    });
};
