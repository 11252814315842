import './main';
import {enableUxTracking} from './enableUxTracking';
import {
    Cookie,
    hasMarketingCookie,
    hasNecessaryCookie,
    hasPerformanceCookie,
    setGdprConsentCookie,
    deleteCookie,
} from './gdprConsentCookie';



namespace CookieConsent {
    const cookieBoxElement = document.querySelector('.cookiebox');
    const cookiePrefElement = document.querySelector('.cookiepref');
    const performanceCookie
        = cookiePrefElement.querySelector(
            '.--checkbox-performance'
    ) as HTMLInputElement;
    const marketingCookie
        = cookiePrefElement.querySelector(
            '.--checkbox-marketing'
    ) as HTMLInputElement;

    const resetModal = (): void => {
        cookieBoxElement.classList.remove('resize');
        document.querySelectorAll('.cookiepref__table').forEach(t =>
            t.closest('.cookiepref__table').classList.remove('active')
        );
    };

    const showCookies = (): void => cookieBoxElement.classList.add('--show');
    const hideCookieModal = (): void =>
        [cookieBoxElement, cookiePrefElement].forEach(e => {
            e.classList.remove('--show');
            resetModal();
        });

    const acceptAllCookies = (): void => {
        const cookies = Object.values(Cookie);
        cookies.forEach(c => setGdprConsentCookie(c, true));
   
        enableUxTracking();
    };

    export const bind = async (): Promise<void> => {
        cookieBoxElement.querySelector(
            '.--btn-cookies'
        ).addEventListener('click', () => {
            hideCookieModal();
            acceptAllCookies();
        });

        cookiePrefElement.querySelector(
            '.--btn-confirm'
        ).addEventListener('click', () => {
            if (performanceCookie.checked) {
                setGdprConsentCookie(Cookie.Performance, true);
                enableUxTracking();
            } else {
                deleteCookie(Cookie.Performance);
            }

            if (marketingCookie.checked) {
                setGdprConsentCookie(Cookie.Marketing, true);
                
            } else {
                deleteCookie(Cookie.Marketing);
            }

            setGdprConsentCookie(Cookie.Necessary, true);
            hideCookieModal();
        });

        if (hasPerformanceCookie()) {
            performanceCookie.toggleAttribute('checked', true);
        }
        if (hasMarketingCookie()) {
            marketingCookie.toggleAttribute('checked', true);
        }

        const prefSettingButton = cookieBoxElement.querySelector('.--manage');
        const arrowButton = cookiePrefElement.querySelector('.--c-arrow');
        [prefSettingButton, arrowButton].forEach(e =>
            e.addEventListener('click', () => {
                cookiePrefElement.classList.toggle('--show');
                resetModal();
            }));

        const cookieFooterLink
            = document.querySelector('.footer__right .show-cookies');
        cookieFooterLink.addEventListener('click', () => {
            showCookies();
            cookiePrefElement.classList.add('--show');
        });

        [cookieBoxElement, cookiePrefElement].forEach(e =>
            e.querySelector('.--c-close').addEventListener('click', () =>
                hideCookieModal()));

        // TODO(jay) Make inEurope method to check user's country correctly.
        if (!hasNecessaryCookie()) {
            showCookies();
            return;
        }
    };
}

window.addEventListener('load', () => {
    void CookieConsent.bind().catch(console.error);
});