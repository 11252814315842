const btnMobile = document.querySelector('.header__btnmenu');
const btnCloseMobile = document.querySelector('.navmobile__button');
const navMobile = document.querySelector('.navmobile');

// BUTTON MENU MOBILE CLICKED
const hideNav = (): void => {
    btnCloseMobile.classList.remove('active');
    navMobile.classList.remove('--show');
};

export const bindMobileNav = (): void => {
    btnMobile.addEventListener('click', () => {
        navMobile.classList.add('--show');
        setTimeout(() => {
            btnCloseMobile.classList.add('active');
        }, 300);
    });
    [btnCloseMobile]
    .map((btn) => btn.addEventListener('click', () => hideNav()));
};
