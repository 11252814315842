export const bindReadMore = (): void => {
    const dots = document.getElementById('dots1');
    const more1 = document.getElementById('more1');
    const expand1 = document.getElementById('expand1');
    expand1.addEventListener('click', () => {
        if (dots.style.display === "none") {
            dots.style.display = "inline";
            expand1.innerHTML = "[Read more]";
            more1.style.display = "none";
          } else {
            dots.style.display = "none";
            expand1.innerHTML = "[Read less]";
            more1.style.display = "inline";
          }
    });
};
