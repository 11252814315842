
import {closeActiveSibling} from './closeActiveSibling';

export const bindCookiePreferences = (): void => {
    const cookiePrefTable = '.cookiepref__table';
    const cookieTitles = document.querySelectorAll('.cookiepref__table-title');
    const cookieBox = document.querySelector('.cookiebox');

    cookieTitles.forEach(title => {
        title.addEventListener('click', () => {
            cookieBox.classList.add('resize');
            title.closest(cookiePrefTable).classList.toggle('active');
            closeActiveSibling(title.closest(cookiePrefTable), 'active');
            if (!title.closest(cookiePrefTable).classList.contains('active')) {
                cookieBox.classList.remove('resize');
            }
        });
    });
};