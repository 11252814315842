import CoCartAPI from "../../../../node/node_modules/@cocart/cocart-rest-api";



const CoCart = new CoCartAPI({
    consumerKey: "",
    consumerSecret: "",
    url: "https://39152-3000.2.codesphere.com/",
});

export const getCartItems = (): void => {
  CoCart.get("cart/items")
  .then((response) => {
    // Successful request
    console.log("Response Status:", response.status);
    console.log("Response Headers:", response.headers);
    console.log("Response Data:", response.data);
    const items = response.data.items
    return items 
  })
  .catch((error) => {
    // Invalid request, for 4xx and 5xx statuses
    console.log("Response Status:", error.response.status);
    console.log("Response Headers:", error.response.headers);
    console.log("Response Data:", error.response.data);
  })
  .finally(() => {
    // Always executed.
  
  });
};

