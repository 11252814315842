import {screen} from './Screen';

const header = document.querySelector('.header');
let prevScrollPos = document.documentElement.scrollTop || document.body.scrollTop;

export const bindHeaderToggleMobile = (): void => {
    /**
     * Creates a timer that is replaced each time, while still scrolling.
     * The onScroll() function is thus called as soon as the user stops scrolling.
     */
    let timer;
    window.addEventListener('scroll', function () {
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(function () {
            onScroll();
        }, 20);
    });
};

const onScroll = () => {
    if (document.body.clientWidth < screen.tablet && header) {
        const currentScrollPos = document.documentElement.scrollTop || document.body.scrollTop;
        if (currentScrollPos <= 0) {
            header.classList.remove('--hide');
            return;
        }
        if (prevScrollPos < currentScrollPos) {
            header.classList.add('--hide');
        } else {
            header.classList.remove('--hide');
        }
        prevScrollPos = currentScrollPos;
    }
};
