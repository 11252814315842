import {closeActiveSibling} from './closeActiveSibling';

export const bindFaq = (): void => {
    const accTitles = document.querySelectorAll('.scfaq__item .scfaq__item-question');
    accTitles.forEach(title => {
        title.addEventListener('click', function() {
            title.closest('.scfaq__item').classList.toggle('active');
            closeActiveSibling(title.closest('.scfaq__item'), 'active');
        });
    });
};

