export interface Screen {
    mobile: number,
    tablet: number,
    desktop: number,
}

export const screen: Screen = {
    mobile: 767,
    tablet: 991,
    desktop: 1199,
};
