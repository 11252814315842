import {getElementWidth} from './getElementSize';

export const bindPricingAnimation = (): void => {
    const boxes = document.querySelectorAll('.scpricing__box');
    const line = document.querySelector('.scpricing__line') as HTMLElement;
    // Does not matter which box exactly since they all have the same width
    const wBox = getElementWidth(boxes[0] as HTMLElement, 'outer');
    const wLine = getElementWidth(line);
    const posDefault = (wBox / 2) - (wLine / 2);

    line.style.transform = `translateX(${posDefault}px)`;
    boxes.forEach(box => {
        box.addEventListener('mouseenter', () => {
            const offsetLeft = (box as HTMLElement).offsetLeft;
            const currentPos = (offsetLeft + (wBox / 2)) - (wLine / 2);

            line.style.transform = `translateX(${currentPos}px)`;
            line.style.opacity = '1';
        });
        box.addEventListener('mouseleave', () => {
            line.style.opacity = '0';
        });
    });
};
