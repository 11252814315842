/**
 * Return the innerWidth/outerWidth/width of an element.
 * @param type type of width. if not set, return the width of the element without padding, border and margin.
 */

export const getElementWidth = (element: HTMLElement, type?: string): number  => {
    if (type === 'inner') {
        // InnerWidth
        return element.clientWidth;
    } else if (type === 'outer') {
        // OuterWidth
        return element.offsetWidth;
    }
    // Width
    const width = window.getComputedStyle(element).width;
    return parseInt(width.match(/\d+/g)[0]);
};
