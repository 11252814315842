import {closeActiveSibling} from './closeActiveSibling';

export const bindMobileFooterAccordion = (): void => {
    const accTitle = document.querySelectorAll('.footer__right .colnav .title');
    accTitle.forEach(title => {
        title.addEventListener('click', function () {
            title.closest('.colnav').classList.toggle('active');
            closeActiveSibling(title.closest('.colnav'), 'active');
        });
    });
};
