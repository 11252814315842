export const bindScrollDownButton = (): void => {
    const btnScrollDown = document.querySelector('.btnscrolldown');
    btnScrollDown?.addEventListener('click', function (event) {
        event.preventDefault();
        const targetID = btnScrollDown.getAttribute('data-scroll-to');
        const target = document.querySelector(`#${targetID}`) as HTMLElement;

        target.scrollIntoView({
            behavior: 'smooth',
        });
    });
};
