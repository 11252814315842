export const bindNotFoundAnimation = (): void => {
    const backHome = document.querySelector('#back-home');
    if (backHome) {
        const sphere = document.querySelector('.sphere-foreground img');
        backHome.addEventListener('mouseenter', () => {
            (sphere as HTMLElement).style.transform = 'translateX(130px)';
        });
        backHome.addEventListener('mouseleave', () => {
            (sphere as HTMLElement).style.transform = 'translateX(0)';
        });
    }
};
