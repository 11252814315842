export const bindMenuItemClick = (): void => {
    document.querySelectorAll('.header a').forEach(link => {
        link.addEventListener('click', () => {
            document.querySelector('.schero__img-earth canvas').classList.add('--hide');
            const loading = document.querySelector('loading');
            loading.classList.add('--show');
            loading.classList.remove('--hide');
        });
    });
};
